@import '../../../resources/styles/variables.less';
.requestUserCard-container {
  padding: 30px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: @box-shadow-border;
  .requestUserCard-title {
    margin-bottom: 9px;
    font-size: 16px;
    color: #2b0d61;
    width: 70%;
    text-align: center;
  }
  .requestUserCard-occupation {
    margin-bottom: 14px;
    font-size: 14px;
    color: #2b0d6180;
  }
  .requestUserCard-campus,
  .requestUserCard-campus-noBn {
    margin-bottom: 31px;
    font-size: 12px;
    color: #2b0d6180;
  }
  .requestUserCard-campus-noBn {
    margin-bottom: 41.5px;
  }
  .requestUserCard-bn {
    width: 102px;
    height: 38px;
    background: #2b0d61;
    border-radius: 8px;
    opacity: 1;
    font-family: @font-poppins-light;
    color: #ffff;
    font-size: 14px;
    margin-bottom: 19.5px;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;