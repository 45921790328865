@import '../../../resources/styles/variables.less';
@import './../../../resources/styles/breakpoints.less';
.company-card-container {
  border: 2px solid #eae9f0;
  padding: 15px;
  border-radius: 10px;

  .company-card-title {
    font-size: 14px;
    color: #2b0d61;
    margin: 10px 0 15px 0;
    font-family: @font-poppins-regular;
  }

  .company-card-row {
    margin-bottom: 21px;
    &.company-firstSection {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
      grid-gap: 8px !important;
      grid-auto-flow: dense;
      .company-address {
        grid-column: span 2;
      }
      .media-breakpoint-max({
        grid-template-columns: repeat(4, 1fr);
      });
    }
    .ant-col {
      padding: 0 !important;
    }
    .company-card-label {
      font-size: 12px;
      color: #aaaaaa;
      display: block;
    }
    .text-input {
      .text-input-label {
        top: 3px;
        left: 16px;
        font-size: 12px;
        color: #aaaaaa;
        opacity: 1;
      }
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;