@import './../../../resources/styles/variables.less';

.drawer-container {
  display: none;
  @media @mobile {
    display: block;
  }

  .ant-drawer-body {
    padding: 0;
    padding-top: @spacing-base;
  }
  .container-image {
    padding-left: @spacing-base;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;