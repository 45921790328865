@import './../../../resources/styles/variables.less';

.ant-steps {
  .ant-steps-item {
    margin: 0 !important;
    padding: 0 !important;

    .ant-steps-item-icon {
      margin: 0 !important;
      padding: 0 !important;
      width: 35px;
      height: 35px;
      font-weight: bold;
      vertical-align: middle;

      .ant-steps-icon {
        color: white;
        top: 1px;
      }
    }

    .ant-steps-item-title {
      padding-right: 0;

      &::after {
        height: 2px;
        opacity: 0.09;
        background-color: #2b3a5a !important;
        top: 17.5px;
      }
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      background-color: #8cbc70;
      border: none;
    }
  }

  .ant-steps-item-active {
    .ant-steps-item-icon {
      background-color: #2B0D6180;  
      border: none;
    }
  }

  .ant-steps-item-wait {
    .ant-steps-item-icon {
      background-color: #dcdcdcc7;
      border: none;
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;