@import '../../../resources/styles/variables.less';
.container-iconsDocumentation{
    padding: 27px;
    .title-iconsDocumentation{
        margin-bottom: 36px;
    }
    .colTitle-iconsDocumentation{
        background-color: #fafdff;
        padding: 8px 0 0 !important;
        margin: 0;
    }    
    .rows-iconsDocumentation{
        display: flex;
        align-items: center;
        width: 25%;
        border-bottom: solid 1px @primary-color;
    }
    .col-iconsDocumentation{
        display: flex;
        justify-content: center;
        padding: 16px;
    }
    
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;