.container-newRequest {
  display: flex;
  flex-direction: row;

  .container-main {
    display: flex;

    .container-step-title {
      display: flex;
    }

    .step {
      text-align: center;
    }

    .ant-steps-current {
      background: #2b0d6180;
      width: 35px;
      height: 35px;
      font-weight: bold;
      vertical-align: middle;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;

      .number-select {
        color: #ffffff;
      }
    }

    .ant-steps-mayor {
      background: #fff;
      border: 2px solid #2b0d6180;

      .number-select {
        color: #2b0d6180;
      }
    }

    .ant-steps-item-tails {
      width: 47px;
      background: #f0f0f0;
      height: 2px;
      border-radius: 6px;
      margin: 12px 0;
    }

    .step-title {
      font-size: 10px;
      width: 76px;
      margin-top: 5px;
      text-align: center;
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;