@import './../../../resources/styles/variables.less';

.container-resumeRequest {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  width: 100%;
  word-wrap: break-word;
  border-radius: 20px;
  color: #828282;

  .title {
    color: #2b0d61;
    text-align: center;
  }
  .status {
    border: 1px solid;
    padding: 3px 17px;
    border-radius: 4px;
  }
  .contract {
    color: #a163f7;
    opacity: 1;
  }
  .mar-bot {
    margin-bottom: 0.5px;
  }

  a {
    background: #ebe9f9 0% 0% no-repeat padding-box;
    border-radius: 8px;
    color: #9684b8;
    font-size: 14px;
    padding: 5px 20px;
    border-color: #9684b8;
  }
  .container-contract {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;

    .icon {
      display: flex;
      padding-right: 10px;
      div {
        display: flex;
      }
    }
    .ant-typography {
      margin: 0;
    }
  }
  .date-contract {
    width: 100%;
    margin: 0;
    padding: 10px;
    .ant-typography {
      white-space: nowrap;
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;