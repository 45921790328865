@import '../../../resources/styles/variables.less';

.loading-container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;

  .ant-spin-dot-item {
    background-color: @third-color;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;