@import './../../../resources/styles/variables.less';

.circle-select {
  width: 100%;
  height: 44px;

  .circle-select-label {
    color: #AAAAAA;
    font-size: 14px;
    font-family: @font-poppins-regular;
    margin-right: 12px;
  }

  .ant-radio-button-wrapper {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    line-height: 2.8;
    margin: 0;

    &:first-of-type {
      margin-right: 14px;
    }

    &:not(.ant-radio-button-wrapper-checked) {
      background-color: #eef0f2;
      color: @primary-color;
    }

    &.ant-radio-button-wrapper-checked {
      background-color: @primary-color;
      color: white;
    }

    &:before {
      display: none !important;
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;