@import '../../../../resources/styles/variables.less';

.container-head {
    display: flex;
    margin-bottom: 38px;

    .obsTag-bn,
    .obsTag-bn-selected {
        display: flex;
        width: 172px;
        height: 64px;
        background-color: #ffff;
        border-radius: 10px;
        align-items: center;
        border: none;
        margin-right: 24px;
        color: #7B7095;
        font-size: 14px;
        cursor: pointer;
    }

    .obsTag-bn-selected {
        background: #EBE9F9;
        color: #2B0D61;
    }
}

.obsTag-container {
    display: flex;

    .observationTag-sideBar {
        min-width: 30%;
        margin-right: 32px;

        .obsTag-sideBar-title {
            margin-bottom: 16px;
            color: #786A99;
            font-family: @font-poppins-light;
            font-size: 14px;
        }

        .obsTag-observation,
        .obsTag-observationSelected {
            background: #FFFFFF;
            border: 1px solid #60605f23;
            border-radius: 10px;
            opacity: 1;
            padding: 12px 8px;
            margin-bottom: 10px;
            cursor: pointer;

            .observation-title {
                font-size: 14px;
                color: #786A99;
                font-family: @font-poppins-light;
                margin-bottom: 6px;
            }

            .observation-description {
                color: #828282;
                font-size: 14px;
            }
        }

        .obsTag-observationSelected {
            border: 1px solid #ED6E00;
        }

        .fileItem-container {
            cursor: pointer;
            border: 1px solid #EAE9F0;
            border-radius: 8px;
            padding: 20px;
            margin: 0 0 10px 0 !important;
        }
    }

    .obsTag-content {
        width: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 20px;
        opacity: 1;
        border: 1px solid #60605f23;
        padding: 20px;
        margin-bottom: 20px;

        .obsTag-content-icons {
            display: flex;
            justify-content: flex-end;    

            .obsTag-content-icon {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                margin-left: 27px;
                cursor: pointer;
                .obsTag--state__icon {
                    cursor: default ;
                }
                .icon-label {
                    color: #ABA7C4;
                    font-size: 12px;              
                }
            }
        }

        .obsTag-content-dividerInformation {
            margin: 10px 0 14.5px;
        }

        .obsTag-content-title {
            color: #2B0D61;
            font-size: 14px;
            font-family: @font-poppins-regular;
            margin-bottom: 28px;
        }

        .obsTag-content-description {
            text-align: justify;
        }

        .obstag-comment{
            .obstag-comment_user-info {
                display : flex;
                flex-wrap: nowrap;
        
                .obsTag-content-dividerComment {
                    margin: 20px 0 25px;
                }
            }
        }
        .obsTag-content-comment {
            display: flex;
            flex-direction: column;
            flex : 1;
            flex-grow: 1;

            .obsTag-comment-user {
                font-size: 14px;
                color: #7B7095;
                margin-bottom: 7px;
            }
            .obsTag-comment-message {
                overflow-wrap: anywhere;
            }
        }
        .obsTag-comment-photoUser {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            padding-right: 20px;
        }
        .obsTag-send-icon {
            display: flex;
            padding-left: 10px;
            align-items: center;
            .comment-loading{
                margin-left: 4px;
            }
            div {
                display: flex;
            }
        }

        .container-edit-observation {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            button {
                margin-top: 10px;
                height: 40px;
            }

            .button-cancel-edit {
                margin-right: 10px;
                border: 1px solid #002452;
                color: #002452;
                background-color: #FFF;
            }
        }
    }

    .observation-date {
        color: #828282;
        margin-bottom: 17px;
        font-size: 12px;
        display: block;
    }

    .mb-0 {
        margin-bottom: 0 !important;
    }
}

.send-icon {
    svg {
        transform: rotate(45deg);

        path {
            fill: black;
        }
    }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;