.filesTree_container {
  min-width: 300px;
  border: 0.5px solid #0f12414d;
  border-radius: 10px;
  .contracts-list_container {
    padding-left: 16px;
    p {
      font-size: 0.9rem;
      margin-top: 3px;
    }
    .d-flex {
      &:hover {
        cursor: pointer;
      }
    }
    .content-children {
      padding-left: 20px;
    }
    .contract-item {
      .drop-down-item {
        display: flex;
        justify-content: space-between;
      }
      .icon-collapse{
        margin-right: 7.5px;
      }
      .icon-collapse-empty{
        display: none;
      }
    }
    .empty-file{
      padding: 0 16px;
    }
    .icon-container {
      margin-right: 13px;
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;