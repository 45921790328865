@import '../../../resources/styles/variables.less';
.contract-modal-container {
  padding: 25px 22px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: @box-shadow-border;
  .section-model,
  .section-justify {
    .title-section {
      color: #2b0d61;
      font-size: 16px;
      font-family: @font-poppins-regular;
    }
    .icon {
      cursor: pointer;
    }
    .row-type-model {
      margin: 10px 0 20px 0;
    }
    .row-file-model {
      gap: 10px;
      flex: 1 1 auto;
      align-items: center;

      .upload-file {
        margin-top: 0px;
      }
      .upload-repository > button,
      .upload-file button {
        border: solid 1px @third-color;
        background-color: #fff !important;
        display: flex;
        gap: 5px;
        align-items: center;
        & > span {
          color: @third-color !important;
          opacity: 1;
        }
      }
      .contract-model {
        flex: 1;
        cursor: pointer;
        .ant-row {
          align-items: center;
          gap: 10px;
          .fileItem-text {
            color: @text-color;
            flex: 1;
          }
        }
      }
    }
  }
  .section-justify {
    margin: 25px 0 20px 0;
    .justify-files {
      margin-top: 20px;
      width: 100%;
    }
    .justify-description {
      margin-top: 20px;
      width: 100%;
      textarea {
        height: 120px !important;
      }
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;