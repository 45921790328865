@import '../../../../resources/styles/variables.less';
.homeAdmin-container {
    .home-title {
        font-size: 25px;
        color: @third-color;
        margin-bottom: 0;
    }

    .header-container {
        display: flex;
        align-items: center;
        margin-top: 7px;
        .home-subtitle {
            font-size: 16px;
            color: @third-color;
            margin-right: 15px;
        }
    }

    hr {
        border: 1px solid #EAE9F0;
        margin-top: 20px;
        margin-bottom: 0;
    }

    .main-container {
        display: flex;
        justify-content: space-between;
        .container-subtitle{
            margin-top: 15px;
            .subtitle {
                font-size: 20px;
                color: @third-color;
                margin-right: 20px;
            }

            .link {
                font-size: 14px;
                color: #2B0D6180;
                cursor: pointer;
            }
        }
        .ant-divider-vertical{
            margin-right: 62.5px !important;
            height: 463px !important;
            border: 1px solid #EAE9F0;
            border-color: #EAE9F0 !important;
        }
    }

}

.v-line{
    border-left: 2px solid #eae9f0;
    height: 60%;
    left: 80%;
    position: absolute;
    top: 29%;
}
.home-calendar{
    margin-top: 15px;
    .divider-calendar{
        border: 1px solid #EAE9F0;
    }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;