.requestStatus-container{
    display: flex;
    background: #ffff;
    border-radius: 10px;
    opacity: 1;
    color: #7B7095;
    padding: 12px 8px;
    align-items: center;
    .requestStatus-text{
        margin-left: 8px;
    }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;