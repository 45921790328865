.user-info-container {
  .divider-image {
    margin: 0;
    border: 1px solid #94a3af80;
    opacity: 0.5;
  }
  .container-image {
    margin: 19px 0 12.5px 0;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;