@import './../../../resources/styles/variables.less';

.responsable-modal {
  .responsableModal-title {
    display: flex;
    justify-content: space-between;
  }
  .closeResponsableModal {
    cursor: pointer;
  }
  .ant-modal-close div div svg {
    margin-top: 14.5px;
    color: @secondary-color;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }

  .ant-modal-body {
    max-height: 538px;
    padding: 21px;
    overflow: auto;

    .rm-title {
      color: @third-color;
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 14px;
    }

    .ant-input-affix-wrapper {
      width: 100%;
      background-color: #f7f8f9;
      border-radius: 10px;
      border: none;
      margin-bottom: 24px;

      &:focus {
        outline: none;
      }

      .ant-input {
        background-color: #f7f8f9;
        color: @primary-color;
        padding-left: 10px;
      }

      .rm-search-icon {
        height: 20px;
      }
    }

    .rm-select-item {
      background-color: white;
      border-radius: 10px;
      transition: background-color 0.3s ease;
      padding: 7px 11px;
      margin-bottom: 15px;
      height: 50px;

      &:hover {
        background-color: #f7f8f9;
        cursor: pointer;
      }

      h4 {
        font-size: 12px;
        font-weight: 600;
        color: @primary-color;
        margin-left: 15px;
        margin-bottom: 0;
      }
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;