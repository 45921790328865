.memberCard-container{
    width: 197px;
    background: #FFFFFF;
    border-radius: 10px;
    padding-top: 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid 1px #F7F6FA;
.memberCard-photo{
    position: relative;
    width: 87px;
    height: 87px;
    border-radius: 50%;
    background-color: #F7F6FA;
    margin-bottom: 20px;
    .memberCard-photod{
        position: absolute;
        width: 63px;
        height: 63px;
        border-radius: 50%;
        top: 12px;
        left: 12px;
    }
}
.memberdCard-name{
    font-size: 14px;
    color: #2B0D61;
}
.memberdCard-occupation{
    color: #ABA7C4;
    font-size: 12px;
    margin-bottom: 38px;
}
.memberCard-footer{
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #EAE9F0;
    width: 100%;
    .memberCard-footer-seeProfile{
        cursor: pointer;
        width: 50%;
        height: 40px;
        display: flex;
        border-left: 1px solid #EAE9F0;
        justify-content: center;
        align-items: center;
        .memberCard-footer-seeProfileText{
            margin-left: 10px;
            color: #7B7095;
            font-size: 10px;
        }
    }
}
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;