.model-observation-modal{
  width: 900px !important;
  .model-observation-body{
    padding: 30px;
    display: flex;
    flex-direction: column;

    .container-text-area{
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
    textarea{
      margin-top: 45px;
      resize: none;
      &:focus{
        //border-color: #ED6E00;
        box-shadow: unset;
      }
    }

    .divider-model-observation{
      margin: 45px 30px 0 !important;
      height: 200px !important;
    }

    .container-description{
      display: flex;
      >div:first-child{
        min-width: 400px;
      }
      .file-container{
        padding-top: 40px;
      }
      .justification-title{
        color: #2B0D61 !important;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 19px !important;
      }
    }
  }
  .icon-delete-container{
    svg{
      path{
        fill: #2B0D61;
      }
    }
  }
  .container-send-button{
    display: flex;
    justify-content: center;
    align-items: center;
    button{
      margin-top: 30px;
      width: 150px;
    }
  }

  .button-cancel{
    margin-right: 10px;
    background: #FFF;
    border: 1px solid #002452;
    color: #002452;
  }

  .row-file{
    .first-item, .second-item, .third-item, .icon-delete-container{
      width: auto;
      flex: unset;
      max-width: unset;
    }
    .second-item{
      flex-grow: 1;
      margin: 0;
      padding-left: 10px;
    }
    .third-item{
      padding: 0 20px;
    }
    .icon-delete-container{
      cursor: pointer;
    }
  }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;