@import './../../../resources/styles/variables.less';

.form-card {
  box-shadow: 5px 0 10px #00000029;
  background-color: white;
  border-radius: 15px;
  width: 100%;
  padding: 25px 26px;
  position: relative;

  .subtitle {
    margin-bottom: 20px;
  }

  .form-card-close-button {
    color: @primary-color;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 0;
    margin: 0;
    transition: opacity 0.3s ease;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;