.button-icon {
    background: #ED6E00 0% 0% no-repeat padding-box;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    border: none;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;

    .icon {
        padding-top: 5px;
        margin-right: 10px;
    }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;