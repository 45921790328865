.fileItem-container {
  padding: 0 10px;
  .ant-col,
  &.ant-row {
    padding: 0 !important;
    margin: 0 !important;
  }
  .fileItem-title {
    color: #2b0d61;
    font-size: 14px;
    word-break: break-all;
  }
  .fileItem-size {
    display: block;
    color: #888888;
    display: 12px;
  }
  .fileItem-download {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;