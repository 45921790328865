.alert-request {
    background: #D0F4E8 0% 0% no-repeat padding-box;
    border-radius: 6px;
    font-size: 12px;
    color: #828282;
    margin: 40px 20px;
    padding: 12px;
    position: relative;
    display: block;
    text-align: left;
    text-decoration: none;

    .alet-typeIcon {
        position: absolute;
        top: -10px;
        left: -10px;
    }
}

.alert-success {
    background: #D0F4E8 0% 0% no-repeat padding-box;
    border: 0.5px solid #00CB89;
}

.alert-warning {
    background: #FFF6BF 0% 0% no-repeat padding-box;
    border: 0.5px solid #FF9C00;
}

.alert-error {
    background: #FFD4DE 0% 0% no-repeat padding-box;
    border: 0.5px solid #F9494A;
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;