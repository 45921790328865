@import './../../../resources/styles/variables.less';
.returnPage-container {
    display: flex;
    font-size: 14px;
    width: 128px;
    height: 24px;
    cursor: pointer;
    font-family: @font-poppins-regular; //change for medium
  .returnPage-text{
    margin-left: 17px;
    color: #2B0D61;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;