@import './../../../resources/styles/variables.less';
@import './../../../resources/styles/breakpoints.less';

.annexes-folder-container {
  width: 100%;
  gap: 15px;
  display: flex;
  &.border-tabRequest {
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 8px;
    padding: 25px 22px;
  }

  .media-breakpoint-xxxl({
    flex-direction: column;
    .annexes-divider{
      display: none
    }
  });

  .annexes-folder,
  .annexes-files {
    flex: 1 1 200px;
    .title-annexes-folder {
      color: @third-color;
      margin-bottom: 0;
    }
    .text-info-folder {
      font-size: 14px;
      color: @fourth-color;
      margin-bottom: 21px;
      display: flex;
      align-items: center;
      margin-top: 5px;
      .icon-disclaimer {
        margin-right: 5px;
        padding-top: 4px;
      }
    }
    .detail-annexes-folder {
      .row-file-folder {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        margin: 6px 0;
        gap: 5px;
        border: 1px solid #fff;
        padding: 5px;
        cursor: pointer;
        align-items: center;

        img {
          width: 30px;
          height: 25px;
        }
        span {
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
        }
        .error-file {
          color: @red-color;
          font-size: 13px;
        }

        &.folder-selected {
          background: @gray-ligth-color;
          border: 1px solid #eae9f0;
          border-radius: 10px;
        }
        &.blue-selected {
          //tab detail
          background: #ecf6ff;
          border: 1px solid #ecf6ff;
          border-radius: 10px;
          display: grid;
          gap: 0;
          grid-template-columns: 40px 1fr;
          grid-template-rows: 1fr 17px;
          grid-template-areas:
            'card1 card2'
            'card1 card3';

          & > div {
            &:nth-child(1) {
              grid-area: card1;
            }
            &:nth-child(2) {
              grid-area: card2;
              .error-file {
                color: @red-color;
              }
              span {
                color: #888888;
              }
            }
            &:nth-child(3) {
              grid-area: card3;
              font-size: 10px;
              .error-file {
                color: @red-color;
              }
              span {
                color: #b3b2b7;
              }
            }
          }
        }
      }
    }
  }
  .annexes-divider > div {
    height: 100% !important;
  }
  .annexes-files {
    .detail-folder {
      .info-file {
        font-size: 15px;
        color: @text-color;
      }
    }

    .upload-file {
      margin-top: 15px;
      .container-upload > span {
        justify-content: center;
        & > div {
          width: auto;
          button {
            background-color: @third-color;
            & > span {
              color: #fff;
              opacity: 1;
            }
          }
        }
        .ant-upload-list.ant-upload-list-text {
          display: none;
        }
      }
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;