.row-file-folder {
  margin-bottom: 5px !important;
  gap: 5px;
  .second-item {
    word-break: break-all;
    font-size: 13px;
  }
  .icon-item {
    cursor: pointer;
    display: flex;
    gap: 8px;
    svg {
      path.colorIcon {
        fill: #fe3a61;
      }
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;