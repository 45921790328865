@import './../../../resources/styles/variables.less';
.detailRequest-representativeCard-container {
  padding: 25px 22px 20px;
  border-radius: 8px;
  margin: 20px 0;
  border: 0;
  box-shadow: @box-shadow-border;

  .detailRequest-containerContent-title {
    font-size: 16px;
    color: #2b0d61;
    font-family: 'Poppins-Regular', sans-serif;
  }
  .detailRequest-containerContent-mb20 {
    margin-bottom: 20px;
  }
  .detailRequest-containerContent-without-border {
    border: 0;
    padding: 20px 0 0 0;
  }
  .row-desc-header {
    justify-content: space-between;
    padding-bottom: 15px;
    .icon-edit {
      justify-content: flex-end;
      display: flex;
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;