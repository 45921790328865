.quickAccessFile-container{
    width: 153px;
    // height: 104px;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    background: #FFFFFF;
    margin-right: 20px;
    padding: 19px 10px 8px 15px;
    .quickAccessFile-icon{
        margin-bottom: 16px;
    }
    .quickAccessFile-footer{
        display: flex;
        justify-content: space-between;
        margin-top: 2px;
        .quickAccessFile-length{
            display: flex;
            .quickAccessFile-length-text{
                margin-right: 3px;
                font-size: 12px;
            }
        }
        .quickAccessFile-size{
            font-size: 12px;
        }
    }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;