.calendar {

    .calendar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;


        .calendar-info {
            display: flex;
            color: #2B0D61;
            font-size: 18px;
            font-weight: 600;

            .month {
                margin-right: 10px;
            }

            .month::first-letter {
                text-transform: uppercase;
            }
        }

        .calendar-arrow {
            display: flex;
            margin-top: 5px;
            cursor: pointer;

            .arrow-left {
                margin-right: 8px;
            }
        }

    }

    .calendar-week {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-gap: 10px;
        font-size: 14px;
        color: #786A99;
        margin-top: 10px;

        .dayWeek {
            text-align: center;
            font-size: 14px;
            color: #786A99;
            font-weight: 500;
        }

        .dayWeek::first-letter {
            text-transform: uppercase;
        }
    }

    .calendar-dates {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        // grid-gap: 10px;
        margin-top: 20px;

        .day {
            text-align: center;
            font-size: 14px;
            color: #786A99;
            padding: 4px;
        }

        .today {
            background-color: #FF7800;
            box-shadow: 0px 0px 5px #270F5D40;
            color: #FFFFFF;
            border-radius: 50%;
        }

        .startDate {
            background: #2B0D6180 0% 0% no-repeat padding-box;
            color: #FFFFFF;
            border-radius: 50%;
        }

        .rangeDate {
            background: #2B0D6126;
            color: #786A99;
        }

        .container-startDate {
            background: #dfdbe7;
            border-radius: 50% 0 0 50%;
        }

        .container-endDate {
            background: #dfdbe7;
            border-radius: 0 50% 50% 0;
        }
    }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;