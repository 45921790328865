@import '../../../resources/styles/variables.less';
.container-confirmationModal{
    padding: 29px 40px 33px;
    background: #FFFFFF;
    border-radius: 8px;
    opacity: 1;
    .confirmationModal-iconClose{
        cursor: pointer;
        position:absolute;
        top:40px;
        right: 40px;
    }
    .container-itemsConfirmation{
        display: flex;
        justify-content: center;
        margin-top: 22.5px;
    }
    .icon-confirmationModal{
        margin-bottom: 18px;
    }
    .title-confirmationModal{
        margin-bottom: 15px;
        color: @primary-color;
        opacity: 1;
        font-size: 24px;
        text-align: center;
    }
    .title-confirmationModal-typeTwo{
        font-family: @font-poppins-bold;
    }
    .subtitle-confirmationModal{
        display: flex;
        margin-bottom: 22.5px;
        opacity: 1;
        font-size: 14px;
        justify-content: center;
    }
    .confirmationModal-divider{ 
        margin: 0;
    }
    .bnOne-confirmationModal,
    .bnTwo-confirmationModal{
        width: 122px;
        height: 38px;
        border-radius: 8px;
        color: #ffff;
        font-family: @font-poppins-light;
        font-size: 14px;
        background: #ED6E00;  
        box-shadow: none;      
    }
    .bnOne-confirmationModal{
        color: #ED6E00;
        margin-right: 18px;
        letter-spacing: 0.1px;
        background: #ffff;
        opacity: none;
        border: 1px solid #ED6E00;
    }

    .bnTwoDisabled-confirmationModal{
        background: @primary-color-disabled;
    }

    .bnOneDisabled-confirmationModal{
        border: 1px solid @primary-color-disabled;
        color: @primary-color-disabled;
    }

    .ant-btn[disabled]:hover{
        border: 1px solid @primary-color-disabled;
        color: @primary-color-disabled;
        background: #ffff;
        cursor: default;
    }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;