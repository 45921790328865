.attachedFile-dropZone{
    display: flex;
    align-items: center;
    border: 2px solid #2B0D61;
    border-radius: 10px;
    height: 60px;
    width: 340px;
    padding-left: 20px;
    justify-content: space-between;
    margin-top: 48px ;
    .attachedFile-fileName{
      margin: 0 11px 0 18px ;
      color: #2B0D61;
    }
    .attachedFile-container-delete{
      background-color: #2B0D61 ;
      width: 60px;
      height: 60px;
      border-radius: 0 9px 9px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
    }
  }
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;