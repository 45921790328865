@import './../../../resources/styles/variables.less';

.filters {
  max-width: 272px;
  width: 100%;

  .filters-title {
    color: @primary-color;
    font-size: 16px;
    font-weight: 600;
  }

  .filters-subtitle {
    color: @primary-color;
    font-size: 14px;
    margin: 0;
  }

  .filters-search-container {
    width: 100%;
    height: 48px;
    margin: 16px 0 25px 0;
  }

  .ant-divider {
    margin: 40px 0 16px 0;
    border-color: @primary-color;
  }

  .ant-checkbox-group {
    margin-top: 16px;

    .ant-checkbox-wrapper {
      width: 100%;
      margin-bottom: 6px;

      span:not(first-of-type) {
        color: @primary-color;
      }

      .ant-checkbox {
        width: 20px;
        height: 20px;
        border: 1px solid #a6b1c2;
        border-radius: 4px;
        padding: 2px;
        margin-right: 16px;

        .ant-checkbox-inner {
          border-radius: 0;
          width: 100%;
          height: 100%;
          border: none;
        }
      }

      .ant-checkbox-checked {
        border: 2px solid @secondary-color;

        .ant-checkbox-inner {
          background-color: @secondary-color;
          border-color: @secondary-color;

          &:after {
            display: none;
          }
        }

        &:after {
          display: none;
        }
      }
    }
  }

  .range-date-picker {
    height: 48px;
    border: 2px solid #a6b1c2;
    border-radius: 8px;
    margin: 11px 0 55px 0;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;