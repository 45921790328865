@import './../../../resources/styles/variables.less';

.custom-datepicker {
  width: 100%;
  border-radius: 15px;
  border: 1px solid #94a3af80 !important;

  input,
  input::placeholder {
    color: @primary-color;
    font-size: 14px;
    font-weight: 600;
  }

  .anticon-calendar {
    color: @secondary-color;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;