.notificationTab-container{ 
    margin-top: 42px;
    .notificationTab-type{
    color: #786A99;
    font-size: 16px;
    margin-bottom: 9px;
    }
    .notifications-container{
        margin: 9px 0;
    }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;