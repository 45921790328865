@import 'font.css';
@import '~antd/dist/antd.less';
@import 'breakpoints.less';
@import 'variables.less';
@import 'util.less';
@import 'antd.less';
// @breakpoints: (s: 0, m: 576px, l: 768px, lg: 992px, xl: 1200px);

body {
  display: flex;
  flex-direction: column;
  //   color: @text-color;
  font-family: @font-poppins-regular;
}
h1 {
  font-family: @font-poppins-bold;
  font-size: @title-font-size;
}

label {
  font-size: @label-font-size;
}

aside.ant-layout-sider {
  box-shadow: @box-shadow;
}

.subtitle {
  color: @primary-color;
  font-size: 16px;
  font-weight: 600;
}

.mt-10 {
  margin-top: 10px !important;
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;