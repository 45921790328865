@import './../../../resources/styles/variables.less';
.alert-container {
  align-items: center;

  flex-wrap: nowrap;
  border-radius: 4px;
  background: #fff2f0;
  padding: 3px 10px;
  margin: 0 -10px;
  .svg-container {
    padding: auto;
    margin: 0 5px;
  }
  .descripcion {
    width: 90%;
  }
  .button-container {
    width: 400px;
    justify-content: flex-end;

    .button1 {
      background: #e5dcdc;
      color: #f95738;
      font-size: 11px;
      width: 78px;
      height: 28px;
      margin: 0px 6px;
      border: none;
      font-weight: bold;
    }
    .button2 {
      background: #f95738;
      color: #e5dcdc;
      width: 75px;
      font-size: 11px;
      height: 28px;
      border: none;
      margin: 0px 3px;
      font-weight: bold;
    }
  }

  label {
    vertical-align: middle;
    font-size: 85%;
    font-weight: 600;
  }
  @media screen and (max-width: 325px) {
    .button-container {
      .button1 {
        justify-content: center;
      }
    }
    .descripcion {
      display: none;
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;