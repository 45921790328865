@import './../../../resources/styles/variables.less';

.text-input {
  position: relative;
  width: 100%;

  input {
    font-size: 14px;
    width: 100%;
    height: 44px;
    border: 1px solid #94a3af80;
    border-radius: 10px;
    font-size: 14px;
    padding: 20px 0 3px 16px;
    color: @primary-color;
    font-weight: 600;

    &:focus ~ .text-input-label,
    &:not(:focus):valid ~ .text-input-label,
    &:not(:focus):disabled ~ .text-input-label {
      top: 3px;
      left: 16px;
      font-size: 12px;
      color: #aaaaaa;
      opacity: 1;
    }

    &:focus {
      border: 1px solid @primary-color;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[readOnly] {
    background-color: #fafafa;
  }

  .text-input-label {
    position: absolute;
    pointer-events: none;
    left: 16px;
    top: 11px;
    transition: 0.2s ease all;
    color: @primary-color;
    opacity: 0.5;
    font-size: 14px;
  }
  .text-input-value {
    color: #888888;
    font-family: @font-poppins-light;
  }

  .text-input-error {
    border-color: @secondary-color;
    // font-family: @font-poppins-light;
  }

  .error-label {
    color: @secondary-color;
    // font-family: @font-poppins-light;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;