@import '../../../resources/styles/variables.less';
.container-annexesModal {
  padding: 30px 40px;

  .iconClose-annexesModal {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    svg {
      height: 30px;
      width: 30px;
    }
  }
  .title-annexesModal {
    margin-bottom: 15px;
    color: @primary-color;
    opacity: 1;
    font-size: 21px;
    text-align: center;
  }

  .row-file {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 10px;
  }

  .upload-file {
    margin-top: 15px;
    .container-upload > span {
      justify-content: center;
      & > div {
        width: auto;
        button {
          background-color: @third-color;
          & > span {
            color: #fff;
            opacity: 1;
          }
        }
      }
      .ant-upload-list.ant-upload-list-text {
        display: none;
      }
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;