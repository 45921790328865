@import './../../../resources/styles/variables.less';
[ant-click-animating-without-extra-node='true']::after {
  display: none;
}
.ant-btn {
  box-shadow: 0px 8px 10px rgba(200, 204, 202, 0.4);
  font-size: 16px;
  border: none;
  font-weight: 600;

  opacity: 1;
  font: normal normal medium 15px Gotham;
  .ant-row.container {
    padding-top: 5px;
    .icon-svg {
      padding-right: 7px;
    }
    .descripcion {
      color: @secondary-color;
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;