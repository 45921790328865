@import './../../../resources/styles/variables.less';

.custom-textarea {
  height: 100px !important;
  border-radius: 15px;

  &::placeholder {
    color: @primary-color;
    font-size: 14px;
    opacity: 0.5;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;