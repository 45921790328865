@import '../../../resources/styles/variables.less';
.annexes-container {
  padding: 25px 22px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: @box-shadow-border;
  .annexes-title {
    color: #2b0d61;
    font-size: 16px;
    margin-bottom: 24px;
    font-family: @font-poppins-regular;
  }
  .annexes-divider {
    margin: 10px 0;
  }
}
.icon {
  cursor: pointer;
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;