.sidebarUser {
  box-shadow: none !important;
  // padding: 15px 0 0 0 !important;
  border: none;
  max-width: none !important;
  min-width: auto !important;
  width: auto !important;
  &.ant-layout-sider-collapsed {
    max-width: 60px !important;
    min-width: 60px !important;
    width: 60px !important;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;