@import './../../../resources/styles/variables.less';

.big-button {
  background-color: @secondary-color;
  border: none;
  padding: 10px 30px;
  border-radius: 8px;
  color: white;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;