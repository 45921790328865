.main-chronology {
    display: flex;

    .summary {
        margin-right: 30px;
        padding-left: 4px;
        width: 280px;

        .calendar {
            margin-top: 40px;
        }
    }

    .chronology {
        padding-right: 4px;
        padding-bottom: 4px;
        flex-grow: 1;
    }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;