.buttonAdd{
    position: relative  ;
    height: 40px;
    background: #2B0D61;
    border-radius: 8px;
    opacity: 1;
    padding: 0 34px 0 46px;
    border: none;
    cursor: pointer;
    .buttonAdd-text,
    .buttonAdd-plus{
        color: #fff;}
    .buttonAdd-plus{
        position: absolute;
        left: 15px;
        bottom: -5px;
        font-size: 30px;
    }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;