@import '../../resources/styles/variables.less';

.d-flex {
  display: flex;
}
.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.d-flex-column {
  display: flex;
  flex-direction: column;
}
.gap-8 {
  gap: 8px;
}
.error-message {
  color: @red-color;
  font-size: 14px;
  line-height: 1.5715;
}
.height-200 {
  height: 200px !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default !important;
}
.event-none {
  pointer-events: none;
}
.border-error {
  border: 1px solid @red-color;
}
