.confirm-answer {
  display: flex;
  background: #fff7f8;
  border-radius: 20px;
  padding: 20px 30px;
  margin-bottom: 20px;

  .exclamatory-icon {
    padding-top: 10px
  }

  .right-container {
    padding-left: 30px;

    .title-confirm-answer {
      color: #2B0D61;
      font-size: 16px;
    }

    .subtitle-confirm-answer {
      color: #828282;
      font-size: 12px;
    }

    .container-buttons {
      padding-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        box-shadow: unset;
        margin-left: 20px;

        span {
          color: #9684B8;
          font-size: 14px;
          font-weight: normal;
        }

        height: auto;
      }

      .left-button {
        background-color: #FFFFFF;
        border: 1px solid #EBE9F9;
      }

      .right-button {
        background-color: #EBE9F9;
      }
    }
  }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;