@import '../../../resources/styles/variables.less';
.modalEnterEmail-container{
    .ant-modal-content{
        padding: 34px 24px 67px;
        width: 479px;
    }
    .modalEnterEmail-iconClose{
        position: absolute;
        top:40px;
        right: 40px;
        cursor: pointer;
    }
    .modalEnterEmail-titleOne{
        color: #2B0D61;
        font-size: 20px;    
    }
    .modalEnterEmail-titleTwo{
        color: #2B0D61 !important;
        font-size: 24px !important;
        margin: 5px 0 34px !important;
        font-family: @font-poppins-regular;
    }
    .modalEnterEmail-subtitle{
        font-size: 16px;
        margin: 0 0 35px;
        width: 100%;
        display: flex;
        text-align: center;
    }
    .modalEnterEmail-RowEmail,
        .modalEnterEmail-rowButtons{                                                                                                    
            display: flex;
            justify-content: center;
            align-items: center;
        .modalEnterEmail-colEmail{
            margin-bottom: 20px;
            .ant-typography{
            color: #786A99;
        padding-left: 15px;}
            font-size: 16px;
        }}                                        
            .modalEnterEmail-rowInputEmail{
                margin-bottom: 45px;
                align-items: center;                
            .modalEnterEmail-inputEmail{
                border: 2px solid #b8b7be;
                font-size: 16px;
            }                                                   
    }
    .modalEnterEmail-rowButtons{
        .modalEnterEmail-bn-cancelar,
        .modalEnterEmail-bn-share{
            width: 122px;
            height: 38px;
            border: 1px solid #ED6E00;
            border-radius: 8px;
            color: #ED6E00;
            box-shadow: none;
            font-family: @font-poppins-light ;
            font-size: 14px;
        }
        .modalEnterEmail-bn-share{
            width: 122px;
            height: 38px;
            border: 1px solid #ED6E00;
            color: #ffff;
            background-color: #ED6E00;
            width: 216px;
            height: 38px;
        }
}
.modalEnterEmail-addDeleteIcon{
    cursor: pointer;
}
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;