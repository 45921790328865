@import '../../../resources/styles/variables.less';
@import './../../../resources/styles/breakpoints.less';

.header-newReqest {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  .title {
    color: #2b0d61;
    font-size: 22px;
    font-weight: 600;
    margin-top: 5px;
    font-family: @font-poppins-regular;
  }
  & > div:first-child {
    flex: 0 1 180px;
  }
  & > div:last-child {
    flex: 0 0 400px;
  }
  .media-breakpoint-xxl({
    flex-direction: column;    
    & > div:first-child ,& > div:last-child {
        flex: 0 1 auto ;
      }
      .container-newRequest{
        justify-content: center;
        .container-main{
            flex-wrap: wrap;
            justify-content: center;
        }
      }
  });
}

.main-newRequest {
  background: #ffffff;
  border: 2px solid #eae9f0;
  border-radius: 20px;
  margin-top: 20px;
  padding: 20px;
}

.footer-button {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  button {
    background: #ffffff;
    border: 1px solid #ed6e00;
    padding: 5px 30px;
    border-radius: 8px;
    color: #ed6e00;
    cursor: pointer;
  }
  .bn-continuar {
    border: 0;
    color: #ffffff;
    background-color: #ed6e00;
  }

  .disable {
    background: #ed6e0080;
    color: white;
  }

  .enable {
    background: #ed6e00;
    color: white;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;