@import '../../../resources/styles/variables.less';
.container-observationModal{
    padding:  44px 24px 30px;
    .ant-modal-content{
        width: 479px;
        height: 380px;
    }
    .container-title-observationModal{
        display: flex;
        justify-content: space-between;
        .first-title,
        .second-title{
            color: #2B0D61;
            opacity: 1;
            font-size: 16px;
            display: block;
        }
        .second-title{
            font-size: 20px;
            margin-bottom: 21px;
        }
    }
    .observationModal-iconClose{
        cursor: pointer;
    }
    .observationModal-select{ 
        border: #2B0D61;
        width: 100%;
        .ant-select-arrow {
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 9px solid #2B0D61;
        }
        .ant-select-selector{
            &:not(.ant-select-customize-input) {
                border : 1px solid #b0acb4;
            }  
        }
       .anticon {
        display: none;
       }
        .ant-select-selection-item,
        .ant-select-selection-placeholder{
            padding: 10px;
        }
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item{
        line-height: 55px;
        padding-left: 8px;
    } 
    .observationModal-selectLabel{
        position: absolute;
        font-size: 12px;
        color: #2B0D61;
        right: 412px;
        top: 132px;
        font-family: @font-poppins-bold;
    }
    .ant-form-item-control-input-content {
        max-height: 132px;
    }
    .input-comments{
        display: block;
        width: 100% !important;
        height: 110px !important;
        background: #F7F8F9;
        border-radius: 10px;
        opacity: 1;
        margin: 10px 0 42px;
        border: none;
        width: 431px;
       
    }
    .container-bn-observationModal{
        display: flex;
        justify-content: center;
        .bn-observationModal{
            width: 122px;
            height: 38px;
            background: #ED6E00;
            border-radius: 8px;
            color: #FFFFFF;
            font-size: 14px !important;
            font-family: @font-poppins-light;
            box-shadow: none;

        }
        .observationModal-cancel{
            background: #ffff;  
            color: #ED6E00;
            border: 1px solid #ED6E00;
        }
    }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;