@import '../../../../resources/styles/variables.less';
@import '../../../../resources/styles/breakpoints.less';

.detailTab-body {
  display: flex;
  gap: 30px;
  .detailTab-containerSidebar {
    flex: 1 1 200px;
    display: flex;
    gap: 40px;
    margin-left: 3px;
    flex-direction: column;
  }

  .detailTab-containerContent {
    flex: 2 1 600px;
    margin-right: 2px;
    .detailTab-containerContent-requestDescription {
      box-shadow: none;
      border: 1px solid #d9d9d9;
    }
    .detailTab-representativeCard-container {
      padding: 25px 22px 2px;
      border-radius: 8px;
      margin: 20px 0;
      border: 1px solid #d9d9d9;
      .detailTab-containerContent-title {
        font-size: 16px;
        color: #2b0d61;
        font-family: @font-poppins-regular;
      }
    }
    .detailTab-containerContent {
      gap: 20px !important;
    }
    .upload-file {
      .container-upload > span {
        & > div {
          width: auto;
          button {
            background-color: @third-color;
            & > span {
              color: #fff;
              opacity: 1;
            }
          }
        }
        .ant-upload-list.ant-upload-list-text {
          display: none;
        }
      }
    }
    &.row-content {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    }
    .save-bn {
      margin: 20px auto;
      background: #ed6e00;
      box-shadow: none;
      font-size: 14px;
      opacity: 1;
      color: #fff;
      height: 38px;
      &.bnOneDisabled {
        background: @primary-color-disabled;
        pointer-events: none;
      }
    }
  }

  .media-breakpoint-xxl({
    flex-direction: column;    
    .detailTab-containerSidebar,  .detailTab-containerContent{
      flex: 0 1 auto ;
    }
    .detailTab-containerSidebar{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
      gap: 25px;
    }
  });
  @media (min-width: 1080px) {
    .detailTab-containerSidebar {
      max-width: 276px;
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;