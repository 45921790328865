@import '../../../../resources/styles/variables.less';
.secondStep-container {
  padding: 16px 38px;
  width: 90%;
  .secondStep-title {
    font-size: 22px;
    color: #2b0d61;
    margin-bottom: 38px;
    font-family: @font-poppins-regular;
  }
  .secondStep-information,
  .secondStep-InformationNull {
    display: flex;
    justify-content: space-between;
    .secondStep-subtitle {
      font-size: 16px;
      color: #2b0d61;
      margin-bottom: 9px;
      font-family: @font-poppins-regular;
    }
    .secondStep-disclaimerContainer {
      display: flex;
      .secondStep-disclaimerText {
        color: #2b0d6180;
        padding-top: 3px;
      }
    }
    .secondStep-bnDeleteJustification {
      cursor: pointer;
    }
  }
  .secondStep-InformationNull {
    margin-bottom: 85px;
  }
  .informationObservation-container {
    width: 100%;
    background: #ffffff;
    border-radius: 20px;
    opacity: 1;
    border: 1px solid #eae9f0;
    padding: 26px 19px 16px;
    margin-bottom: 41px;
    .observationCard-container {
      display: flex;
      align-items: center;
      .observationCard-icon {
        margin-left: 20px;
        cursor: pointer;
      }
      .observationCard-category {
        background: #ffffff;
        box-shadow: 3px 3px 10px #37495729;
        border-radius: 20px;
        opacity: 1;
        padding: 26px 19px;
        margin: 0 41px 10px 0;
        width: 90%;
        .observationCard-categoryTitle {
          font-size: 14px;
          color: #2b0d61;
          font-family: @font-poppins-regular;
          margin-bottom: 15px;
        }
      }
    }
  }
  .secondStep-justificationModel {
    padding: 21px 30px 35px;
    display: flex;
    margin-top: 19px;
    gap: 15px;
    width: 100%;
    .secondStep-justificationModel_container {
      flex: 1;
    }
    .ant-spin-nested-loading {
      width: 50%;
    }
    .secondStep__justificationDropzone {
      width: 100%;
      &--error{
        .container-dropzone{
          border: 2px dashed @red-color;
        }
      }
    }
    .justification-title {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      color: #2b0d61 !important;
    }

    .secondStep-justificationDivider {
      margin: 0 30px !important;
      height: 200px !important;
      background-color: #eae9f0 !important;
    }
    .secondStep-justificationInput {
      background: #f7f6fa;
      border-radius: 10px;
      opacity: 1;
      padding: 17px 19px;
    }
    .container-justification {
      width: 50%;
      flex-direction: column;
      textarea {
        height: 199px;
      }
    }
    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
      .secondStep-justificationDivider {
        display: none;
      }
      .ant-spin-nested-loading {
        width: 100%;
      }
      .container-justification {
        width: 100%;
      }
      .secondStep-justificationModel_container {
        width: 100%;
      }
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;