.modalMyUnit-container{
    width: 997px !important;
    height: 608px !important;
.modalMyUnit-header{
    padding: 27px 24px 0;
}
    .ant-modal-close{
        display: none;
    }
    .modalMyUnit-title{
        font-size: 25px !important;
        margin: 0 0 35.5px !important;
        color: #2B0D61;
    }
    .ModalMyUnit-bnClose{
        cursor: pointer;
    }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;