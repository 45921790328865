@import './../../../resources/styles/variables.less';

.side-bar {
  display: flex;
  width: 76%;
  right: 0px;
  align-items: flex-end;
  flex-direction: column;
  position: absolute;
  z-index: 100;
  transition: 0.7s ease;

  .container {
    background-color: #ffffff;
    opacity: 1;
    width: -1500px;
    height: 600px;
    border-radius: 7px;
    box-shadow: -2px 3px 6px #00000029;
    display: flex;
    flex-direction: column;

    .contenido-top {
      width: 290px;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
      height: 45px;
      border-radius: 3px;
      box-shadow: 0px 1px 0px 0px rgba(202, 202, 202, 0.75);

      h5 {
        color: #6a7284;
        font: normal normal medium 16px Gotham;
      }

      .icon-svg {
        cursor: pointer;
      }
    }

    .contenido-bottom {
      display: flex;
      max-width: 290px;
      text-align: center;
      height: 80px;
      margin: 3px 3px;
      text-align: center;

      align-content: center;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;

      .ant-btn {
        display: flex;

        color: @primary-color;
        font-size: 14px;
        width: 250px;
        justify-content: space-between;

        text-align: center;
        box-shadow: none;
        border: 1px solid #d3d2d2;
        color: @primary-color;
        align-items: center;
        opacity: 0.8;
      }

      .title {
        opacity: 1;
        font: normal normal Gotham;
        font-size: 14px;
        letter-spacing: 0.1px;
        color: @primary-color;
        margin-top: 4px;
        text-align: left;
        display: flex;
        width: 80%;
        justify-content: flex-start;
      }

      .buton-container {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding-right: 18px;

        .button {
          background: @secondary-color;
          color: #ffffff;
          width: 77px;
          box-shadow: 1px 1px 3px #6a728480;
          font-size: 13px;
          height: 34px;
        }
      }

      .validate-input {
        position: relative;
      }

      .wrap-input1 {
        width: 90%;
        border: 1px solid #d3d2d2;
        border-radius: 10px;
        margin-bottom: 20px;
        margin-top: 20px;
      }

      .label-input1 {
        margin-top: 10px;
        font-size: 13px;
        color: @secondary-color;
        font-weight: bold;
        display: flex;
        line-height: 1.2;
        text-transform: uppercase;

        span {
          margin-left: 25px;
        }

        ::placeholder {
          color: @primary-color;
        }
      }

      input {
        height: 28px;

        color: @primary-color;
      }

      .input1 {
        display: block;
        width: 100%;
        background: transparent;
        border: none;
        font-size: 14px;
        color: @primary-color;
        line-height: 1.2;
        padding: 0px 26px;
        outline: none;

        &::placeholder {
          color: @primary-color;
        }
      }
    }
  }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;