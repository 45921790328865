@import '../../../resources/styles/variables.less';
@import './../../../resources/styles/breakpoints.less';

.container-login {
  display: flex;
  padding: 30px 45px;
  gap: 25px;
  .firstSection-login {
    flex: 1 1 550px;
    .firstSection-subcontainer {
      margin-left: 9vw;
    }

    .titleOne-login {
      font-family: @font-poppins-light;
      margin: 14% 0 0;
      color: @third-color;
      font-size: 25px;
    }

    .titleTwo-login {
      font-size: 32px;
      margin: 0 0 41px;
      color: @third-color;
    }
  }

  .secondSection-login {
    display: flex;
    justify-content: center;
    margin: 12% 0 0;
    flex: 1 1 350px;
    .col-login {
      display: flex;
      justify-content: center;
      & > div {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
      .google-button {
        display: flex;
        gap: 10px;
        padding: 5px 15px;
        width: 100%;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: 2px solid @third-color;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
          background: #e3ddea;
        }
        span {
          color: @third-color;
        }
      }

      .titleThree-login {
        font-size: 30px;
        font-family: @font-poppins-bold;
        margin: 0 0 45px;
        color: @third-color;
      }

      .inputUser-login {
        position: relative;
        width: 100%;

        input {
          height: 65px;
          width: 325px;
          background: #f3f1f6;
          padding: 18px 27px 0;
          color: @third-color !important;
          font-size: 16px;
          font-family: @font-poppins-regular; //to change for medium font
          border-radius: 8px;
          border: none;
          font-weight: 100;

          &:focus ~ .label-inputUser,
          &:not(:focus):valid ~ .label-inputUser {
            top: 12px;
            left: 27px;
            font-size: 14px;
            color: @secondary-color;
            opacity: 1;
          }

          &:focus {
            border: 1px solid @primary-color;
          }
        }

        input[type='text']:focus {
          outline: none;
          border: none;
        }

        input[type='password']:focus {
          outline: none;
          border: none;
        }

        .label-inputUser {
          position: absolute;
          pointer-events: none;
          left: 16px;
          top: 16px;
          transition: 0.2s ease all;
          color: @secondary-color;
          font-size: 19px;
        }

        .icon-password {
          cursor: pointer;
          position: absolute;
          right: 20px;
          top: 20px;
        }
      }

      .captchaContainer-Login {
        margin: 23px 0 36px;
      }

      .bnEnter-login {
        color: #ffff;
        border: 0;
        width: 170px;
        height: 41px;
        background: #ff7800;
        border-radius: 5px;
        opacity: 1;
        font-size: 18px;
        margin: 0 auto;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .alert-login {
      color: @secondary-color;
      font-size: 14px;
    }
  }
  .media-breakpoint-xl({
    flex-direction: column;
    .firstSection-login , .secondSection-login{
      flex: 0 1 auto ;    
      text-align: center;
      margin: 0;
    }
    .firstSection-subcontainer {
      margin: 0;
    }
  });
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;