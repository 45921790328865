.membersTemplate-title{
    font-size: 30px !important;
    color: #2B0D61 !important;
}
.memberTemplate-memberCards{
    margin-bottom: 24px;
}
.membersTemplate-pagination{
    display: flex;
    justify-content: center;
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;