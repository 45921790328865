@import './variables.less';
.ant-layout {
  background-color: white !important;
}

.ant-layout-content {
  @media @mobile {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.site-layout-background {
  @media @mobile {
    padding: 10px !important;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f0ecf4;
}

.ant-select-dropdown {
  border: 1px solid #160c41;
  border-radius: 8px;
  padding: 0 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #7b78803d;
}

//Sidebar
.ant-menu {
  margin-top: 25px;
}
.ant-menu-item::after {
  left: 0 !important;
  right: 0 !important;
  border: 0 !important;
}

.ant-menu-item-selected {
  span.ant-menu-title-content {
    font-family: @font-poppins-bold;
  }
}

.ant-divider-vertical {
  height: 25px !important;
  border-color: #a19fa0 !important;
  margin: auto !important;
}

header.ant-layout-header {
  margin-left: 0 !important;
  @media @mobile {
    padding: 0 @spacing-base !important;
    background-color: @primary-color !important;
    path {
      fill: white !important;
    }
  }
}

.ant-checkbox {
  border: 1px solid #53504d;
  border-radius: 3px;
}

.ant-btn {
  border-radius: @border-radius;
  height: @min-height-btn;
}

//Aside
aside {
  @media @mobile {
    // display: none;
    position: absolute !important;
  }
}

.ant-menu-submenu-arrow {
  color: @primary-color !important;
}

.ant-layout-sider-collapsed .ant-layout-sider-trigger {
  width: 60px !important;
}

.ant-modal-body {
  padding: 0;
}

.hideCloseButton {
  .ant-modal-close-x {
    display: none;
  }
}

.select-input {
  .ant-select-selector {
    background-color: #ffffff;
  }
}

.ant-col,
.ant-row,
.ant-form-item {
  padding: 0;
  margin: 0;
}
