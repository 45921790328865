@import '../../../resources/styles/variables.less';

.notificationCard-container {
    display: flex;
    justify-content: space-between;
    padding: 16px 12px;
    background: #FFFFFF;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    border: 1px solid #60605f23;
    margin-bottom: 15px;

    .notificationCard-firstSection {
        display: flex;
        width: 70%;

        .notificationCard-text {
            margin-left: 7px;
            color: #828282;
            width: 100%;

            .notificationCard-user {
                color: #786A99;
                opacity: 1;
                font-size: 16px;
                font-family: @font-poppins-regular;
                margin-bottom: 3px;
            }

            .notificationCard-description {
                color: #828282;
            }

            .notificationCard-date {
                display: block;
                color: #ABA7C4;
                font-size: 12px;
                margin-top: 12px;
            }

            .container-edit-notification {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                button {
                    margin-top: 10px;
                    height: 40px;
                }

                .button-cancel-notification {
                    margin-right: 10px;
                    border: 1px solid #002452;
                    color: #002452;
                    background-color: #FFF;
                }
            }
        }
    }

    .notificationCard-iconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        cursor: pointer;

        .notificationCard-icon,
        .notificationCard-iconAccept {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .notificationCard-iconAccept {
            margin-right: 27px;
        }
    }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;