@import './../../../resources/styles/variables.less';

.search-input {
  border: 2px solid #e0dcdc;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .ant-input-prefix {
    width: 18px;
    height: 18px;
    margin-top: 4px;
    margin-right: 10px;
  }
  input {
    font-weight: 100;
    height: 100%;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;