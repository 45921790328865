@import './../../../resources/styles/variables.less';
.box-container {
  max-width: 305px;
  width: 100%;
  height: 100%;
  @media @mobile {
    .info-container {
      display: flex;
      justify-content: center;
    }
  }
  .info-container {
    h4 {
      padding: 10px 0px;
      font-size: large;
      color: @primary-color;
    }
  }
  .card-container {
    box-shadow: 0px 4px 7px;
    margin: auto;
    min-height: 344px;
    word-wrap: break-word;
    .object1-container {
      width: 100%;
      .subtitle {
        font-size: 15px;
        color: @orange-color;
        font-weight: bold;
        margin: 3px 0;
        .icon-svg {
          margin: 0px 3px;
        }
      }
      .descripcion {
        font-size: 14px;
        padding: 3px 0px;
        font-weight: 500;
        color: @primary-color;
      }
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;