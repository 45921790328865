@import '../../../resources/styles/variables.less';
.detailRequest-containerHeadRow{
    display: flex;
    justify-content: space-between;
    .detailRequest-containerHead-title{
        font-size: 22px;
        color: #2B0D61;
        margin: 0 6px 0 0;
    }
    .detailRequest-containerHead-number{
        font-size: 22px;
        color: #2B0D6180;
        margin-right: 46px;
    }
    .detailRequest-containerHead-tag{
        width: 81px;
        height: 21px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        }
    .detailRequest-containerHead-bn{
        background-color: #ED6E00;
        width: 101px;
        height: 38px;
        border-radius: 8px;
        opacity: 1;
        box-shadow: none;
        color: #ffff;       
        font-size: 14px;
        font-family: @font-poppins-light;
        margin-right: 15px;
        &:hover{
            background-color: #ED6E00;
            color: #ffff;
        }
        &:focus{
            background-color: #ED6E00;
            color: #ffff;
        }
    }  
}
.detailRequest-containerHead-divider{
    width: 100%;
    box-shadow: 0px 3px 6px #270F5D29;
    margin: 25px 0;
}
.detailRequest-containerHead-requestData {
    display: flex;
    align-items: center;
}

.ant-modal-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    .informative-modal{
        top: unset;
    }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;