@import '../../../resources/styles/variables.less';
.requestDescription-container,
.requestDescription-container-editable {
  padding: 20px 26px;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 8px;
  .requestDescription-EditTitle {
    color: #2b0d61;
    font-size: 16px;
    font-family: @font-poppins-light;
  }
  & > .requestDescription-firstSection {
    gap: 8px !important;
  }
  .requestDescription-dateAndSelect {
    position: relative;
    width: 100%;
    // margin: 20px 0;
    .ant-select-selector {
      background: #ffffff;
    }
    .ant-picker-input > input {
      font-weight: 100;
    }
  }
  .requestDescription-dateAndSelect-data {
    .ant-picker-input {
      padding-top: 10px;
    }
  }
  .requestDescription-description {
    height: 103px !important;
  }
  .requestDescription-description-data {
    padding-top: 20px;
    background-color: #ffffff;
  }
  .requestDescription-select {
    .ant-select-selector {
      height: 54px;
      padding: 5px 10px;
    }
  }
  .requestDescription-label {
    z-index: 1;
    position: absolute;
    color: #aaaaaa;
    font-size: 12px;
    top: 11px;
    left: 22px;
  }
  .label-description {
    top: 22px;
    left: 18px;
  }

  .date-picker-selected {
    padding: 5.5px 10px;
    background-color: #ffffff;
    .ant-picker-input {
      input {
        top: 5px;
      }

      .ant-picker-suffix {
        top: -3px;
        position: relative;
      }
    }
  }
}
.requestDescription-container-editable {
  .row-desc-header {
    padding-top: 20px;
    justify-content: space-between;
    .icon-edit {
      justify-content: flex-end;
      display: flex;
      cursor: pointer;
    }
  }
}

.detailRequest-containerContent-requestDescription {
  .ant-select-selector {
    height: 45.5px !important;
    align-items: center;
  }
}

.requestDescription-container {
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 45.5px;
    align-items: center;
  }

  .requestDescription-dateAndSelect.request-disabled,
  .requestDescription-dateAndSelect.request-disabled > .ant-select-selector,
  textarea:disabled {
    background-color: #fafafa;
    color: #888888;
    pointer-events: none;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;