.fouthStepRequest-toggle {
  margin-bottom: 20px;
}
.fouthStepRequest-containerRepresentative {
  margin: 20px 0 13px 0;
  .firstRepresentativeCard-delete {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    cursor: pointer;
  }
}
.add_representative-orange{
    color:#ed6e00;
    display: flex;
    width: 260px;
    align-items: center;
    cursor: pointer;
    .add_icon{
        padding-left: 6px;
        padding-top: 6px;
    }

}

.header-company-container {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  .header-company-row {
    .select-col-company .select-input {
      width: 180px;
    }

    .btn-add-circle {
      width: 180px;
    }
  }

  .firstRepresentativeCard-delete {
    padding-top: 0;
    margin-left: 20px;
  }
}

.dropdown-company {
  .ant-select-item {
    background-color: #ffffff;
  }
}

.btn-delete-company {
  display: flex !important;
  justify-content: flex-end;
  .buttonClean{
    margin-right: 10px;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 35px;
    min-width: 200px;
    background: #2B0D61;
    border-radius: 8px;
    opacity: 1;
    border: none;
    cursor: pointer;
    color: #ffffff;
    .refresh_icon{
        padding-right: 4px;
        padding-top: 14px;
    }
  }
  .firstRepresentativeCard-delete {
    cursor: pointer;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;