@import './../../../resources/styles/variables.less';

.select-input {
  width: 100%;
  height: 44px;

  .ant-select-selector {
    border-radius: 10px !important;
    border: 1px solid #94a3af80 !important;
    padding: 0 15px !important;

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      color: #aaaaaa;
      font-size: 14px;
    }
  }
  .ant-select-arrow {
    margin-right: 9px;

    .select-suffix-icon {
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-bottom: 0;
      border-top: 8px solid @secondary-color;
    }
  }
}
.read-only.ant-select {
  cursor: default;
  & > .ant-select-selector {
    background: #fafafa !important;
    pointer-events: none;
  }
  span.ant-select-selection-item {
    color: #888888;
    font-family: @font-poppins-light;
    font-weight: 600;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;