@import url('./../../../resources/styles/variables.less');

.container-main {
  .ant-space-item {
    padding: @spacing-base !important;
  }

  .header {
    display: flex;
    justify-content: flex-end;
    margin: 17px 20px 10px 0;
    border-radius: 12px;
    background: #f7f7f7;
    padding: 0 20px;
    span {
      color: @primary-color;
      font-weight: bold;
    }
    .user-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      align-items: center;
      .container-search {
        display: flex;
        height: 40px;
        width: 55%;
        margin-right: 218px;
      }
      .container-user {
        display: flex;
        align-items: center;
        margin-right: 13px;
      }
      .icon-notification {
        display: flex;
        align-items: center;
        padding: 15px 15px 0 0;
      }
      .text-user {
        font-size: 14px;
        letter-spacing: 0;
        color: #002452;
        text-align: end;
      }
      .text-user-profile {
        font-size: 10px;
        color: #c9c4d4;
        text-align: end;
        line-height: 12px;
      }
      .divider-user {
        margin: 5px !important;
        height: 50px !important;
        opacity: 0.5;
        background: #c9c4d4;
        width: 1px;
        border: none;
      }
      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin: 0 15px;
      }
      .user-icon svg {
        width: 40px;
        height: 40px;
      }
    }
    .icon > div {
      display: flex;
      margin: 0 15px;
      cursor: pointer;
    }
    .icon {
      &:first-of-type {
        padding-right: @spacing-base;
      }
    }
    .user-container-mobile {
      display: none;
    }
    @media @mobile {
      .user-container {
        display: none;
      }
      .user-container-mobile {
        display: flex;
        width: 100%;
        justify-content: space-between;
        img {
          width: 56px;
          height: auto;
          object-fit: contain;
        }
        .icon svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .headerUser {
    background: white;
    display: flex;
    width: 100%;
    border-bottom: 2px solid #eae9f0;
    border-radius: 0;
    .user-containerUser {
      display: flex;
      width: 290px;
    }
  }
  .site-layout {
    width: 60%;
  }
  .container-menuSidebar {
    border-right: 2px solid #eae9f0;
    .container-logoSidebar {
      align-items: center;
      padding: 17px 21px 17px 23px;
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;