@import '../../../resources/styles/variables.less';
@import './../../../resources/styles/breakpoints.less';

.representativeCard-container {
  padding: 22px 23px 8px;
  border: 2px solid #eae9f0;
  border-radius: 8px;
  background-color: #ffff;
  .representativeCard-title {
    font-size: 14px;
    color: #2b0d61;
    margin-bottom: 27px;
    font-family: @font-poppins-regular;
  }
  .representativeCard-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    margin-top: 10px;
    grid-gap: 8px !important;
    grid-auto-flow: dense;

    .media-breakpoint-max({
      grid-template-columns: repeat(4, 1fr);
    });

    .representative-address {
      grid-column: span 2;
    }

    .representativeCard-label {
      font-size: 12px;
      color: #aaaaaa;
      display: block;
    }
    .text-input {
      .text-input-label {
        top: 3px;
        left: 16px;
        font-size: 12px;
        color: #aaaaaa;
        opacity: 1;
      }
    }
  }

  .header-company-row .select-col-company {
    margin: 0;
  }

  .representativeCard-header {
    align-items: center;
    .representativeCard-title {
      margin: 0;
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;