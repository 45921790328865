@breakpoint_sm: 482px;
@breakpoint_md: 592px;
@breakpoint_lg: 652px;
@breakpoint_xl: 822px;
@breakpoint_xxl: 1024px;
@breakpoint_xxxl: 1200px;
@breakpoint_xxxxl: 1600px;

//rules
.media-breakpoint-max( @rules) {
  @media screen and (min-width: @breakpoint_xxxxl) {
    @rules();
  }
}
.media-breakpoint-xxxl( @rules) {
  @media screen and (max-width: @breakpoint_xxxl) {
    @rules();
  }
}
.media-breakpoint-xxl( @rules) {
  @media screen and (max-width: @breakpoint_xxl) {
    @rules();
  }
}
.media-breakpoint-xl( @rules) {
  @media screen and (max-width: @breakpoint_xl) {
    @rules();
  }
}
