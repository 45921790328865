.requestType-container {
  margin: 28px;
  justify-content: center;
  .requestType-headContainer {
    text-align: center !important;
    .requestType-title {
      font-size: 18px !important;
      color: #2b0d61 !important;
      margin-bottom: 22px !important;
    }
  }
  .requestType-container-options {
    display: flex;
    justify-content: center;
    margin-top: 41px;
    gap: 3%;
    .requestType-optionContainer {
      width: 163px;
      height: 198px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 2px solid #eae9f0;
      border-radius: 15px;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 22px;
      color: #2b0d61;
      font-weight: 600;
    }
    .requestType-optionContainer-selected {
      background: #f7f6fa;
      border: none;
    }
  }
}
.container-error {
  margin-top: 20px;
  text-align: center;
  span {
    color: red;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;