@import '../../../resources/styles/variables.less';
.myUnit-containerBody{
    padding: 0 24px 94px;
    .container-searchBar{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16.5px;
        .title-searchBar{
            font-size: 18px;
            color: #2B0D61;
            margin: 0;
        }
        .searchBar-MyUnit{
            width: 30%;
            height: 34px;
            padding: 0 28px;
            border: 1px solid #94A3AF80;    
            .ant-input{
                font-size: 12px ;
                color: #2B0D61;
                opacity: 0.3;       
            }
        }
    }
    .divider-searchBar{
            margin: 0;
            border-color: #94A3AF80;
    }
    .container-files{
        display: flex;
        margin-top: 36.5px;
        .container-file{
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #94A3AF80;
            border-radius: 8px;
            width: 182px;
            height: 46px;
            display: flex;
            align-items: center;
            padding: 0 18px;
            align-items: center;
            margin: 0 9px 16px 0;
            cursor: pointer;
            .file-icon{
                margin-right: 15px;
            }
            .file-name{
                font-size: 14px;
                color: #2B0D61;
                font-family:@font-poppins-regular ; // to change for medium
            }
        }

    }
    .title-files{
        color: #2B0D61 !important;
        opacity: 1;
        margin-bottom: 15px !important;
    }

    .container-doc{
        width: 182px;
        height: 161px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #94A3AF80;
        border-radius: 4px;
        padding: 33px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin:0 9px 9px 0;
        cursor: pointer;
        .container-docName{
            display: flex;
            color: #2B0D61;
            font-size: 12px;
            justify-content: center;
            align-items: center;
            width: 100%;
            width: 180px;
            height: 42px;   
            .doc-icon{
                    margin-right: 12px ;
            }
        }
    }
    .container-docSelected{
        border: 1px solid #518FF5;
        .container-docNameSelected{
            background: #94a3af44;
        }
    }
}
.myUnit-containerBody-docSelected{
    margin-bottom: 0;
}

.myUnit-container-footer{
    background-color: #ECF3FE;
    box-shadow: 0px -1px 6px #00000029;
    display: flex;
    align-items: center;
    padding: 0 22px 0 12px;
    .footer-close{
        cursor: pointer;
    }
    .footer-textStyle{
        font-size: 14px;
        color: #2B0D61;
    }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;