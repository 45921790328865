@import '../../../resources/styles/variables.less';
.requestTemplate-dFlex {
  display: flex;
  justify-content: space-between;
  .requestTemplate-title {
    font-size: 25px;
    color: #2b0d61;
  }
  .requestTemplate-bn-newRequest {
    position: relative;
    width: 160px;
    height: 35px;
    background: #ed6e00;
    border-radius: 4px;
    opacity: 1;
    box-shadow: none;
    padding-left: 40px;
    margin-bottom: 49px;
    .bn-newRequest-text,
    .bn-newRequest-plus {
      color: #ffff;
      font-size: 14px;
      font-family: @font-poppins-light;
      font-weight: 200;
    }
    .bn-newRequest-plus {
      font-size: 26px;
      position: absolute;
      top: -2px;
      left: 17px;
    }
    &:hover {
      background-color:#ed6e00;
    }
  }

  svg {
    cursor: pointer;
  }

  .disabled-icon {
    svg {
      cursor: not-allowed;
      path {
        opacity: 0.5;
      }
    }
  }
}
.requestTemplate-statusContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  .requestTemplate-requestStatus {
    margin-right: 15px;
    cursor: pointer;
  }
}
.requestTemplate-searchInput-container {
  margin: 20px 0;
  width: 70%;
}

.observations-quantity {
  text-align: center;
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;