@import './../../../resources/styles/variables.less';

.user-info-card {
  width: 100%;
  min-width: 256px;
  height: 165px;
  box-shadow: 0 8px 16px #0a043c29;
  border-radius: 8px;
  padding: 10px 19px;

  .user-info-card-header {
    color: #5a607f;
    font-weight: 600;
  }

  .state-badge-ball {
    width: 10px;
    height: 10px;
    border-radius: 6px;
    opacity: 1;
  }

  .user-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .user-data-date {
    color: @primary-color;
    font-size: 12px;
  }

  .user-name {
    margin-bottom: 4px;
    font-size: @small-font-size;
    font-weight: 600;
    color: @primary-color;
  }

  .user-request {
    font-size: @small-font-size;
    color: @primary-color;
    font-weight: 600;
    margin: 13px 0;

    span {
      color: #646d82;
      font-weight: normal;
    }
  }

  .user-date {
    font-size: 12px;
  }

  span {
    margin-left: 8px;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;