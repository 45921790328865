.container-chronoly {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    padding: 25px;
    width: 100%;
    .ant-steps-vertical {
        padding-left: 20px;
        padding-top: 20px;

        .ant-steps-item {
            .ant-steps-item-container {
                .ant-steps-item-tail::after {
                    width: 5px;
                    height: 100%;
                    margin-left: -1px;
                }

                .ant-steps-item-icon {
                    .ant-steps-sinicon {
                        background-color: #fff;
                    }
                }

                .process-wait {
                    border: 2px solid;
                    background: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .number {
                        color: #7B7095;
                        font-weight: 600;
                    }
                }

                .process-complete {
                    border-color: #ffffff;
                }
            }

            .ant-steps-item-content {
                display: flex;
                flex-shrink: 1;
                .ant-steps-item-title {
                    padding-top: 5px;
                    margin-left: 10px;
                    font-size: 14px;
                    color: #7B7095;
                    line-height: 20px;

                    .subtitle {
                        font-size: 12px;
                        font-weight: 100;
                        max-width: 310px;
                        margin-bottom: 2em;
                    }

                    .botton {
                        margin-bottom: 30px;

                        a {
                            background: #EBE9F9 0% 0% no-repeat padding-box;
                            border-radius: 8px;
                            padding: 10px 25px;
                            color: #9684B8;
                        }
                    }
                }

                .ant-steps-item-title::after {
                    background: #fff;
                }
            }
        }

        .ant-steps-item-finish {
            .ant-steps-item-container {
                .ant-steps-item-tail::after {
                    background-color: #8cbc70;
                }
            }

        }

    }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;