.container-itemNotification {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 15px;

    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;

    .container-main{
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin: 0;
        padding: 0px 10px 0px 8px;
        width: 650px;
    }

    .container-icon{
        display: flex;
        flex-direction: row;
        align-items: center;

        .mar-10{
            margin-right: 10px;
        }
        .mt-4{
            margin-top: 4px;
        }
        .icon{
            text-align: center;
        }
    }

    .title{
        color: #786A99;
        font-size: 16px;
        margin: 0;
    }

    .description{
        color: #828282;
        font-size: 14px;
        margin: 0;
        padding-top: 4px;
    }

    .text-icon{
        font-size: 12px;
        color: #ABA7C4;
    }

    .hour{
        color: #ABA7C4;
        font-size: 12px;
        padding-top: 7px;
        margin: 0;
        padding-top: 12px;
    }

    .alert{
        background: #6BC741 0% 0% no-repeat padding-box;
        height:14px;
        width:15px;
        -moz-border-radius:50px;
        -webkit-border-radius:50px;
        border-radius:50px;
        margin-top: 3px;
    }


}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;