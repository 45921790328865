@import '../../../resources/styles/variables.less';
.menuUser {
  background-color: white;
  border: none;
  margin: 0;
  font-size: 16px;
  color: #2B0D61;
  .menu-option {
    height: 53px !important;
    padding: 0 18px 0 !important;
    margin: 0 !important;
    &.ant-menu-item-selected {
      border-left: 4px solid #f95738;
      color: #ED6E00;
      background-color: white;
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;