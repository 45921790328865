@import '../../../resources/styles/variables.less';
.justification-container {
  padding: 25px 22px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  margin-top: 20px;
  .justification-title {
    margin-bottom: 26px;
    font-size: 16px;
    color: #2b0d61;
    font-family: @font-poppins-regular;
  }
  .justification-labelDescription {
    display: block;
    color: #aaaaaa;
    font-size: 12px;
    margin-bottom: 5px;
  }
  .justification-description {
    margin-bottom: 26px;
    display: flex;
    text-align: justify;
  }
  .justification-filesContainer {
    display: flex;
    .justification-filesContainerColOne,
    .justification-filesContainerColTwo {
      width: 50%;
    }
    .justification-filesContainerColOne {
      margin-right: 22px;
      margin-left: 7px;
    }
    .justification-filesContainerColTwo {
      margin-left: 22px;
    }
  }
  .justification-divider {
    margin: 10px 0;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;