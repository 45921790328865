@import '../../../resources/styles/variables.less';

.modal-notification{
    .ant-modal-content {
        height: auto !important;
        padding: 37px 32px;
        border-radius: 20px;
    }
}
.container-modalEnter{
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-input {
      margin: 0 0;
    }
    .modalEnter-closeIcon{
        position: absolute;
        right: 24px;
        top: 24px;
        cursor: pointer;
    }
    .modalEnter-titleOne{
        font-family: @font-poppins-light;
        margin: 0;
        color: #2B0D61;
    }
    .modalEnter-titleTwo{
        font-size: 24px;
        font-family: @font-poppins-regular;
        margin: 5px 0 33px !important;
        color: #2B0D61;
    }
    .modalEnter-subTitle{
        font-size: 12px !important;
        margin-bottom: 33px;
        text-align: center;
    }
    .modalEnter-cards{
        width: 88px !important;
        height: 89px !important;
        box-shadow: none;
        font-size: 12px;
        display: flex;
        align-items: flex-end;
        margin-bottom: 45px;
        border-radius: 10px;
        padding-bottom: 10px;
        cursor: pointer;
        .modalEnter-cardtext{
            text-align: center;
        }
    }
    .modalEnter-cards-typeOne{
        background: #E1E7FC;
        color: #526DB8;
    }
    .cards-typeOne-selected{
        border: 2px solid #526DB8;
    }
    .modalEnter-cards-typeTwo{
        background: #FEF5E6;
        color: #FFBB4F;
    }
    .cards-typeTwo-selected{
        border: 2px solid #FFBB4F;
    }
    .modalEnter-cards-typeThree{
        background: #E5F4F6;
        color: #76AFB3;
    }
    .cards-typeThree-selected{
        border: 2px solid #76AFB3;
    }
    .modalEnter-footer-bn{
        background: #ED6E00;
        box-shadow: none;
        font-size: 14px;
        opacity: 1;
        color: #fff;
        width: 122px;
        height: 38px;
    }
    .footer-bn-cancelar{
        color: #ED6E00;
        background: #fff;
        border: 1px solid #ED6E00;
    }
}
.ant-form-item-control-input-content{
    max-height: 276px;
   
}
.ant-form-item-control-input{
   margin-top: 3px;
   margin-bottom: 3px;
   padding: 1px;
}
.ant-form-item-explain.ant-form-item-explain-error {
    padding-left: 17px;
  }

.ant-row.modalEnter-footer-buttons {
    padding: 8px;
  }
.modalEnter-footer-buttons{
    display: flex;
    justify-content: space-evenly;  
}
.ant-modal-close{
    display: none;
}
.modalEnter-size {
  .ant-modal-content {
    width: 369px;
    height: 399px;
  }
}
.ant-form-item-control-input-content {
    max-height: 244px;
}
.modalEnter-size-optionOne{
    .ant-modal-content{
        width: 369px;
        height: 504px; 
    }  
    .modalEnter-firstOption-input{
        display: block;
        width: 310px;
        background: #F7F8F9;
        border-radius: 10px;
        opacity: 1;
        margin-bottom: 49px;
        border: none;
    }
}
.modalEnter-size-optionTwo{
    .ant-modal-content{
        width: 369px;
        height: 447px; 
   
    }  
}
.modalEnter-Option-subtitle{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 21px;
}
.modalEnter-container-checkbox{
    display: flex;
    margin-bottom: 10.5px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .modalEnter-checkbox{
        padding-top:4px ;
        .ant-checkbox{
            border: none;
        }
        .ant-checkbox-checked{
        .ant-checkbox-inner{
            background-color: #ED6E00 ;
            border: 1px solid #ED6E00;
        }}
    }
    .modalEnter-checkbox-label{
        margin-left: 8px;
    }
}
.modalEnter-secondOption-input{
    margin-bottom: 28px;
    width: 310px;
    height: 92px !important;
}

.notification-subtype{
    margin-bottom: 20px;
    .ant-radio-inner:after{
        background-color: #ED6E00;
    }
    .ant-radio-checked .ant-radio-inner{
        border-color: #ED6E00;
    }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
        border-color: #ED6E00;
    }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;