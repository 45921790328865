@import './../../../resources/styles/variables.less';
.card-container-main {
  width: 100%;
  margin: 20px 10px;
  .title {
    h3 {
      color: #002452;
      font-weight: bold;
      padding: 10px 0;
    }
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    padding: 0 0;
    width: 260px;
    color: #002452;
    font-weight: bold;
    cursor: pointer;
    .ant-collapse-arrow {
      position: absolute;
      left: 235px;

      .svg-container {
        transition: 0.3s transform ease-in-out;
        display: inline-block;
      }
    }
  }
  .comment-container {
    height: 330px;
    overflow: scroll;

    div.ant-row {
      border-bottom: 1px solid;
      border-image: linear-gradient(to bottom, rgb(255, 254, 254), rgb(243, 243, 243));
      border-image-slice: 0.5;
      margin-top: 20px;
      .descripcion-container {
        width: 90%;
        padding: 0px 30px;
      }
      .date {
        color: #96a5b7;
      }
      h4 {
        color: #002452;
      }
    }
    .descripcion {
      padding-top: 10px;
      text-align: justify;
      color: #002452;
      margin-bottom: 20px;
    }

    svg {
      color: #ffffff;
      background-color: #ffffff;
      vertical-align: 'middle';
    }
  }
  ::-webkit-scrollbar {
    width: 7px;
    height: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #fae8d2;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: @secondary-color;
    border-radius: 10px;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;