.repository-containerTitle{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20.5px;
    .repository-title{
        font-size: 30px;
        margin-bottom: 0;
    }
    .repository-bn-actualize{
        margin-top: 17px;
        width: 101px;
        height: 38px;
        background: #ED6E00;
        border-radius: 8px;
        opacity: 1;
        box-shadow: none;
        color: #ffff;    
        font-size: 14px;
        font-weight: 100;
    }
}
.repository-containerBody{
    display: flex;
    .repository-body-sideBar{
        width: 25%;
        .body-sideBar-menu{
            display: flex;
            margin-bottom: 22px;
            font-size: 14px;
            color: #2B0D61;
            .body-sideBar-iconDrive{
                margin-right: 7px;
            }
        }
        .repository-menuItem{
            display: flex;
            margin-bottom: 20px;
            cursor: pointer;
            .repository-menuItem-iconCollapsed{
                margin-right: 9px;
            }
            .repository-menuItem-icon{
                margin-right: 18px;
            }
        }
        .repository-body-menu{
            border: 0;
        }
    }
    .repository-body-content{
        width: 65%;
        .repository-body-quickAccess {
            margin-bottom: 30px;
            font-size: 14px;
            color: #2B0D61;
        }
        .repository-body-table{
            width: 100%;
        }
    }
}
.temp-container-files{
    display: flex;
    margin-top: 20px;
.temp-container{
    width: 153px;
    height: 104px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    margin: 20px 20px 0 0;
}
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;