@import './../../../resources/styles/variables.less';
.ant-menu {
  margin-top: 52px;
  background: #f7f7f7;
  .option-logout {
    font-weight: bolder;
    margin-top: 300px;
    color: #f95738;
  }
}

li.ant-menu-item {
  height: 50px !important;
  padding-left: 35px !important;
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;