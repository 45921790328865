.container-detailRequest {
  .ant-tabs-nav-wrap {
    display: inline-block !important;
    justify-content: flex-start;
    padding-right: 170px;
    flex: auto;
  }

  .ant-tabs-content-holder {
    margin: 20px 0;
  }

  .ant-tabs-top {
    flex: 1;
  }
  .ant-tabs-extra-content {
    display: flex;
  }
}
.detailRequest-return {
  margin-right: 10px;
  height: 70px;

  .title {
    color: #2b0d61;
    font-size: 22px;
    font-weight: 600;
  }
}
.detailRequest-bnCounterpart {
  cursor: pointer;
  &.disabled > button {
    background-color: #bfbfbf;
    cursor: not-allowed;
  }
  &.left {
    margin-right: 8px;
    right: 190px;
  }
}

.centered-spin {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/*
.ant-tag-blue{
    color: #000;
    background: #e6f7ff;
    border-color: #91d5ff;
}*/

@media (max-width: 1400px) {
  .container-detailRequest {
    .ant-tabs-nav {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .ant-tabs-nav-wrap {
      order: 2;
      justify-content: flex-start;
    }
  }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;