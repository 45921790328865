@import '../../../resources/styles/variables.less';
@import './../../../resources/styles/breakpoints.less';

.representativeCard-container {
  padding: 22px 23px 8px;
  border: 2px solid #eae9f0;
  border-radius: 8px;
  background-color: #ffff;
  .representativeCard-title {
    font-size: 14px;
    color: #2b0d61;
    margin-bottom: 27px;
    font-family: @font-poppins-regular;
  }
  .representativeCard-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    margin-top: 10px;
    grid-gap: 8px !important;
    grid-auto-flow: dense;

    .media-breakpoint-max({
      grid-template-columns: repeat(4, 1fr);
    });

    .item--large {
      grid-column: span 2;
    }

    .representativeCard-label {
      font-size: 12px;
      color: #aaaaaa;
      display: block;
    }
    .text-input {
      .text-input-label {
        top: 3px;
        left: 16px;
        font-size: 12px;
        color: #aaaaaa;
        opacity: 1;
      }
    }
  }

  .header-company-row .select-col-company {
    margin: 0;
  }

  .representativeCard-header {
    display: flex;
    justify-content: space-between;
    .representativeCard-title {
      margin: 0;
    }
  }
  .row-upload-powerVigency {
    display: flex;
    gap: 10px;
    flex: 1 1 auto;
    align-items: center;

    .upload-file {
      margin-top: 0px;
    }
    .upload-repository > button,
    .upload-file button {
      border: solid 1px @third-color;
      background-color: #fff !important;
      display: flex;
      gap: 5px;
      align-items: center;
      padding: 8px;
      & > span {
        color: @third-color !important;
        opacity: 1;
      }
    }
    .filename-powerVigency {
      flex: 1;
      cursor: pointer;
      .ant-row {
        align-items: center;
        gap: 10px;
        .fileItem-text {
          color: @text-color;
          flex: 1;
          overflow-wrap: anywhere;
        }
      }
    }
  }
}

.summaryCard-container {
  margin: 10px 0;
  border-bottom: 1px solid #e1e0e2;
  .summary-title {
    span {
      font-weight: 500;
      font-size: 16px;
      color: #8a888f;
    }
  }
  .summary-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text-detail {
      display: flex;
      gap: 7px;
      span {
        font-size: 13px;
        &.error-item {
          color: #ff4d4f;
        }
      }
    }
    .actions-detail {
      display: flex;
      gap: 5px;
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;