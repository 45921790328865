@import '../../../../resources/styles/variables.less';
.row-file {
  gap: 8px;
}
.thirdStepRequest-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 19px;
  .thirdStepRequest-title {
    font-size: 18px;
    color: @third-color;
    font-weight: 600;
    margin-bottom: 12px;
    font-family: @font-poppins-regular;
  }
}
.thirdStepRequest-requestDescription {
  margin: 0 46px;
}
.thirdStepRequest-containerCard {
  display: flex;
  padding: 21px 30px;
  margin: 30px 46px 15px;

  .detail-folder {
    .icon-delete {
      svg {
        height: 15px;
        width: 15px;
        path {
          fill: @third-color;
        }
        cursor: pointer;
      }
    }
    .icon-delete-container {
      display: flex;
      justify-content: flex-end;
    }
    .file-name-label {
      color: @third-color;
    }
    .error-file {
      color: @red-color;
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;