@import '../../../resources/styles/variables.less';
.container-informativeModal {
  padding: 29px 56px 33px;
  background: #ffffff;
  border-radius: 20px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  .ant-modal-content {
    width: 499px;
    height: 280px;
  }
  .container-items {
    display: flex;
    justify-content: center;
    .icon-informativeModal {
      margin-bottom: 8px;
    }
  }
  .title-informativeModal {
    margin-bottom: 18px;
    letter-spacing: 0px;
    color: #002452;
    opacity: 1;
    font-size: 24px;
    text-align: center;
  }
  .subtitle-informativeModal {
    letter-spacing: 0px;
    opacity: 1;
    font-size: 14px;
    text-align: center;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;