@import './../../../resources/styles/variables.less';
.title-dropZone,
.title-dropZone-wDisclaimer {
  color: @third-color !important;
  margin-bottom: 0 !important;
}
.title-dropZone-wDisclaimer {
  margin-bottom: 19px !important;
}
.disclaimer-dropZone {
  font-size: 14px;
  color: #2b0d6180;
  margin-bottom: 21px;
  display: flex;
  align-items: center;
  .icon-disclaimer {
    margin-right: 5px;
    padding-top: 4px;
  }
}
.container-dropzone {
  background: #f7f6fa;
  border: 2px dashed #eae9f0;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 40px;
  font-size: 14px;
  line-height: 21px;
  color: #160c41;
  height: 201px;
  &.error-dropzone {
    border: 2px dashed @red-color;
  }
  .uploadText-dropZone {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: #888888;
    width: 245px;
    margin-top: 20px;
  }
  .uploadBn-dropZone {
    width: 114px;
    height: 40px;
    background: @third-color;
    border-radius: 8px;
    color: #ffff;
    margin-top: 17px;
    font-family: @font-poppins-regular;
    padding: 0;
    font-size: 14px;
  }
  .blueBold {
    color: #2b0d61;
    font-family: @font-poppins-bold;
  }

  .upload-btn-disabled {
    background-color: #2b0d6180;
    cursor: not-allowed;
  }
}
.error-file {
  color: @red-color;
  font-size: 14px;
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;