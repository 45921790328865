@import './../../../resources/styles/variables.less';

.request-container {
  @media @mobile {
    text-align: center;
  }
  margin: 10px;
  max-width: 100%;

  .title {
    h3 {
      color: @primary-color;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .ant-radio-button-wrapper {
    margin-right: 10px;
    border-radius: 8px;
    font: normal normal medium 17px/30px Gotham;
    letter-spacing: 0px;
    color: #002452;
    font-weight: bold;
    width: 145px;
    height: 50px;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    position: absolute;
    top: 5px;
    left: 0px;
    width: 0.5px;
    height: 37px;
    padding: 1px 0;
    transition: background-color 0.3s;
    content: '';
  }
  .descripcion-container {
    margin: 0px 8px;
    text-align: left;
    font: normal normal medium 14px/30px Gotham;
    letter-spacing: 0px;
    color: @primary-color;
    opacity: 0.5;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;