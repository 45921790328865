@import './../../../resources/styles/variables.less';

.ant-table-thead {
  border: none;
  tr {
    .ant-table-cell {
      background-color: #F7F6FA;
      height: 52px;
      padding: 0 16px;
      font-weight: 600;
    }
    th {
      color: #7B7095;
      font-family: @font-poppins-light;
      border-bottom: 0;
      }  
    th::before{
      background-color: transparent !important;
    }
    .ant-table-cell:first-of-type {
      border-radius: 10px 0 0 10px;
    }
    .ant-table-cell:last-of-type {
      border-radius: 0 10px 10px 0;

    }  
  }
  &::after {
    line-height: 10.5px !important;
    content: '.' !important;
    color: white;
    display: block !important;
  }
}
.ant-table-tbody {
  tr{ 
    td{
      border-bottom: 0;
      height: 44px;
      color:#828282;
    }
  }
}
.ant-pagination {
  align-items: center;
  font-size: 12px;
  justify-content: center;
  .ant-pagination-item-active {
    background-color: #806c9c;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    padding-top: 2px;
    a {
      color: white;
      
    }
  }
  .ant-pagination-item {
    border: none;
    font-size: 14px;
    color: #ABA7C4;
  }
  .ant-pagination-item-link {
    border-radius: 8px;
    background-color: #f0ecfc;
    border: none;
  }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;