@import './../../../resources/styles/variables.less';
@import './../../../resources/styles/breakpoints.less';

.firstRepresentativeCard-container {
  padding: 21px 30px 30px !important;
  .firstRepresentative-titleContainer {
    display: flex;
    justify-content: space-between;
    .firstRepresentative-title {
      color: @third-color;
      font-size: 16px;
      font-family: @font-poppins-regular;
      margin-bottom: 9px;
    }
    .firstRepresentativeCard-delete {
      cursor: pointer;
    }
  }
  .firstRepresentative-subtitleContainer {
    display: flex;
    margin-bottom: 20px;
    .firstRepresentative-subtitleIcon {
      margin-right: 7px;
    }
  }
  .firstRepresentative-container {
    padding-bottom: 30px;
    .firstRepresentativeCard-row {
      gap: 10px !important;
      .media-breakpoint-max({
        display: grid;
        grid-template-columns: repeat(4, 1fr);
      });
    }
    .ant-select-selection-placeholder {
      color: #002452;
      opacity: 0.5;
      font-size: 14px;
    }
  }
}

.text-button {
  background-color: transparent;
  border: none;
  padding: 10px 30px;
  color: #646d82;
  font-weight: 600;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;