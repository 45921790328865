.header-company-row {
  gap: 8px;
  .btn-add-circle {
    background-color: #f2f0f8;
    padding: 0 5px;
    width: 138px;
    
    .icon {
      margin-right: 5px;
    }

    svg {
      height: 16px;
      width: 16px;
      path {
        stroke: #786a99;
      }
    }

    .title-label {
      color: #786a99;
    }
  }

  .select-col-company {
    .select-input {
      height: inherit;
      width: 124px;
    }
    .ant-select-selector {
      height: 40px;
      padding: 0 10px !important;
    }
  }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;