@import './../../../resources/styles/variables.less';

.btn-upload {
  height: 44px;
  border: 1px solid #94a3af80;
  border-radius: 10px;
  width: 100%;
  text-align: left !important;
  box-shadow: none;
  span {
    color: #002452;
    opacity: 0.5;
    font-size: 14px !important;
    font-weight: 100;
  }
}

.container-upload {
  & > span {
    display: flex;
    & > div {
      width: 50%;
    }
  }
  .btn-upload.error {
    border: 1px solid @red-color;
  }
  .error-file {
    color: @red-color;
    font-size: 14px;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;