.tab-custom {
    display: flex;
    padding-top: 10px;

    span {
        margin-left: 8px;
    }

    .icon {
        margin-top: 3px;
    }

    .quantity {
        margin-left: 10px;
        text-align: center;

        .quantity-item {
            background-color: #ABA7C4;
            color: #FFFFFF;
            font-size: 12px;
            border-radius: 45%;
            padding: 0px 10px;
        }
    }
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 1px solid #ffffff;
    content: '';
}

.ant-tabs {
    color: #ABA7C4;
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar {
    height: 5px;
}

.ant-tabs-ink-bar {
    background: #2B0D61;
    border-top-left-radius: 50px 50px;
    border-top-right-radius: 50px 50px;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
    padding-left: 14px;
}

.v-line {
    border-left: 2px solid #DCD7FC;
    height: 40%;
    left: -13% !important;
    position: absolute;
}

.ant-tabs-nav-wrap-ping-right {
  order: 2;
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;