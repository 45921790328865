@import '../../../../resources/styles/variables.less';
@import '../../../../resources/styles/breakpoints.less';

.container-dropZone {
  margin-top: 20px;
  padding: 21px 30px;
}
.fifthStepRequest-containerCard {
  margin: 20px 0 0 0;
  padding: 21px 30px;

  .fifthStepRequest-content {
    width: 100%;
    gap: 15px;
    flex-wrap: wrap;
    display: flex;
    .fifthStepRequest-dropZone-container,
    .fifthStepRequest-dropZone-input {
      flex: 1 1 200px;
    }
     .fifthStepRequest-fileList {
      margin-top: 10px;
    }
    .fifthStepRequest-divider > div {
      height: 100% !important;
    }

    .media-breakpoint-xxxl({
       flex-direction: column;
       .fifthStepRequest-divider{
         display: none
       }
     });
  }
}

.fifthStepRequest-file-contract {
  width: 500px;
  margin-top: 30px;
  border: 3px solid @third-color;
  border-radius: 10px;
  // padding: 10px; 
  .icon-delete-container {
    background-color: @third-color;
    justify-content: center;
    padding: 10px 0;
    .icon-delete {
      cursor: pointer;
      svg {
        height: 18px;
        width: 18px;
        path {
          fill: @white-color;
        }
      }
    }
  }

  .row-file {
    align-items: stretch;
    padding-left: 10px;
    gap: 8px;
    .ant-col {
      display: flex;
      align-items: center;
    }

    .icon-disclaimer > div {
      display: flex;
    }
  }
}

.fifthStepRequest-fileList {
  .row-file {
    margin-top: 10px;
    gap: 8px;
  }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;