@import './../../../resources/styles/variables.less';

.toggle-container{  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
.toggle-title {
      color:@third-color !important;
      font-size: 18px !important;
      margin: 0 0 10px !important;
      font-family: @font-poppins-regular;
  }
  .toggle-subtitle {
    color: #2B0D6180;
    font-size: 14px;
    font-weight: lighter;
  }
  .toggle-options{
    background: #FFFFFF ;
    border: 1px solid #94A3AF80;
    border-radius: 10px;
    box-shadow: none;
    color: #888888;
    font-size: 14px;
    width: 146px;
    height: 49px;
    margin-top: 20px;
    cursor: pointer;
    padding: 0 15% ;
  }
  .toggle-option-selected{
    background: #EBE9F9;
    border: none;
    font-family: @font-poppins-regular; //to change for medium font
  }
}
@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;