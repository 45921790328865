@import './../../../resources/styles/variables.less';

.home-container {
  padding: 35px 0;
  .home-title-container {
    display: flex;
    justify-content: space-between;
    .home-title {
      color: #ed6e00;
      font-size: 25px;
      margin: 0;
    }
    .home-subtitle {
      color: @third-color;
      font-size: 16px;
    }
    .home-bn-newRequest {
      background: #ed6e00;
      border-radius: 6px;
      width: 171px;
      height: 40px;
      opacity: 1;
      box-shadow: none;
      color: #ffff;
      font-size: 14px;
      font-family: @font-poppins-light;
      font-weight: 500;
      padding-right: 6px;
      padding-left: 19px;
      .bn-newRequest-content {
        display: flex;
        justify-content: space-between;
        .bn-newRequest-text {
          margin-top: 2px;
        }
        .bn-newRequest-plus {
          position: relative;
          width: 26px;
          height: 26px;
          background: #ed6e00;
          border-radius: 6px;
          opacity: 1;
          font-size: 26px;
          .newRequest-plus-text {
            position: absolute;
            color: #ffff;
            top: -7px;
            right: 5px;
          }
        }
      }
    }
  }
  .calendar {
    width: 250px;
    height: 228px;
    .ant-picker-calendar-header {
      display: none;
    }
    .ant-picker-cell-selected {
      .ant-picker-cell-inner {
        background-color: #2b0d6180;
        border-radius: 50%;
      }
    }
  }

  .home-subtitle {
    font-size: 16px;
    color: @third-color;
    font-weight: 600;
    margin-bottom: 8px;

    &.form-responsable {
      margin-top: 50px;
    }
  }

  .home-date-and-type {
    margin-bottom: 48px;

    .home-span-text {
      font-size: 16px;
      color: @primary-color;
    }

    .home-calendar-icon div {
      height: 18px;
      margin: 0 15px 0 5px;

      svg * {
        fill: @secondary-color !important;
      }
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;