@import '../../../../resources/styles/variables.less';
@import '../../../../resources/styles/breakpoints.less';

.detailRequest-body {
  display: flex;
  gap: 25px;
  padding: 15px 0;

  .detailRequest-containerSidebar {
    flex: 1 1 200px;
  }
  .detailRequest-containerContent {
    flex: 2 1 600px;
    .detailRequest-containerContent-head {
      display: flex;
      justify-content: space-between;
      margin: 0 0 21px 23px;
      .detailRequest-iconText {
        display: flex;
        .detailRequest-iconText-icon {
          margin-right: 10px;
          padding-top: 3px;
        }
      }
    }
    .detailRequest-containerContent-requestDescription {
      box-shadow: none;
      border: 1px solid #d9d9d9;
      .ant-select-selector {
        height: 45.5px;
        align-items: center;
      }
    }
    .detailRequest-representativeCard-container {
      padding: 25px 22px;
      border-radius: 8px;
      margin: 20px 0;
      .detailRequest-containerContent-title {
        font-size: 16px;
        color: #2b0d61;
        font-family: @font-poppins-regular;
      }
    }
    .detailRequest-containerContent-mb20 {
      margin-bottom: 20px;
    }
  }

  .media-breakpoint-xxl({
    flex-direction: column;    
    .detailRequest-containerSidebar,  .detailRequest-containerContent{
      flex: 0 1 auto ;
    }
   .detailRequest-containerSidebar{
    display:flex;
    gap: 20px;
    & > div{
      flex: 1;
    }
   }
  });
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;