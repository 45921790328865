@import '../../../../resources/styles/variables.less';
.requestType-headContainer {
  .requestType-title {
    text-align: center;
    color: @third-color;
    font-size: 18px;
    font-family: @font-poppins-regular;
  }

  .requestType-subtitle {
    text-align: center;
    color: #2b0d6180;
    font-size: 14px;
  }
}

.main-responsableDetail {
  background: #ffffff;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 20px;
  padding: 25px;
  margin: 20px 30px 30px 30px;

  .header-card {
    display: flex;
    justify-content: space-between;

    .card-title {
      color: @third-color;
      font-size: 16px;
      margin-bottom: 5px;
      font-family: @font-poppins-regular;
    }

    .card-subtitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 15px;

      .text {
        color: #2b0d6180;
        margin-bottom: 5px;
        margin-left: 5px;
      }
      .error {
        color: red;
      }

      .icon {
        margin-bottom: 5px;
        margin-right: 10px;
      }
    }
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;